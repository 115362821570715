import fetch from '@rd-web-markets/shared/src/lib/services/Fetcher';
import { request } from '@rd-web-markets/shared/dist/services/request';
import { buildService } from '@rd-web-markets/shared/dist/services/service';
import serviceMethods from '@rd-web-markets/shared/dist/services/service_methods';
import { useCallback } from 'react';

const statusesApi = buildService('/claim_groups/{id}/technical_narrative_upload_status', { only: [ 'all', 'base' ] }, { only: ['postFormData'] })

const importNarrativeService = {
  create(claimGroupId, formData) {
    return fetch(`/api/claim_groups/${claimGroupId}/technical_narrative_imports/`, {
      ...request.post,
      headers: {
        'Accept': 'application/json, text/plain, */*'
      },
      body: formData
    });
  },
}

const useFetchTechnicalNarrativeImportStatusWithInterval = ({ claimGroupId, setLoading }) => {
  const {
    interval,
    setInterval,
    stateVar: importStatus,
    setStateVar: setImportStatus
  } = serviceMethods.useFetchWithInterval({
    api: statusesApi,
    callback: useCallback(async () => await statusesApi.all(claimGroupId), [claimGroupId]),
    setLoading
  })

  return { importStatus, setImportStatus, interval, setInterval }
}

const claimGroupTechnicalNarrativeService = {
  ...importNarrativeService,
  useFetchTechnicalNarrativeImportStatusWithInterval
}

export default claimGroupTechnicalNarrativeService;
