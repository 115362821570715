import React, { useEffect, useRef, useState } from 'react'
import { Row, Col} from 'react-bootstrap';
import { Table } from '@rd-web-markets/shared/dist/util';
import { Button } from '@rd-web-markets/shared/dist/util/buttons';
import CostTemplateErrorsModal from '@components/modals/CostTemplateErrorsModal';
import { createTimezonedDate } from '@rd-web-markets/shared/dist/util/date';

const TechnicalNarrativeTemplateUploadSection = ({
  loading,
  setLoading,
  claimGroup,
  handleToaster,
  uploadDocument,
  documentUrl,
  uploadButtonText,
  downloadButtonText,
  statuses,
  showDownloadButton,
  useFetchStatusWithInterval = () => {}
}) => {
  const hiddenFileInput = useRef(null);
  const [modalShow, setModalShow] = useState(false);

  const {importStatus: lastImportStatus, setImportStatus: setLastImportStatus, interval: fetchImportStatusInterval } = useFetchStatusWithInterval({ claimGroupId: claimGroup.id, setLoading })

  if (lastImportStatus?.import_status === 'pending' && !fetchImportStatusInterval?.started) {
    fetchImportStatusInterval.start()
    setLoading(true)
  } else if (lastImportStatus?.import_status !== 'pending' && fetchImportStatusInterval?.started) {
    fetchImportStatusInterval.clear()
    loading && setLoading(false)
  }
  const handleClick = () => {
    hiddenFileInput.current.click();
  };

  const submitUpload = async (e) => {
    if(e.target.files[0].type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
      setLastImportStatus({ import_status: 'pending' })
      await uploadDocument(e);
      hiddenFileInput.current.value = null;
    } else {
      handleToaster('Please upload docx file', 'warning')
    }
  }

  const generateDocument = () => {
    window.open(documentUrl);
  };

  const rows = () => {
    return (lastImportStatus?.import_errors || []).map((error) => ({
      columns: [error]
    }));
  }

  const modalBody = () => {
    return <Table headers={[{ text: 'Error' }]} rows={rows()} showControls={false} />;
  }

  const renderStatus = () => {
    if(lastImportStatus){
      switch(lastImportStatus.import_status) {
        case 'imported_successfully':
          return 'Template was imported successfully';
        case 'imported_unsuccessfully':
          return (
            <span>
              There were errors importing the file.
              <Button style={{padding: 0, margin: 0}} variant="link" onClick={() => setModalShow(true)}>
                You can check them here
              </Button>
            </span>
          )
        case 'never_imported':
          return 'Template was never imported'
        default:
          return 'Pending'
      }
    } else { 
      return 'Template was never imported';
    }
  }

  const importTableHeaders = [
    { text: 'Date' },
    { text: 'Uploaded By' },
    { text: '' }
  ];

  const importTableRows = () => {
    return statuses?.filter(im => im.import_status !== 'never_imported').reverse().map((im) => ({
      columns: [
        createTimezonedDate(new Date(im.created_at), true).toCustomLocaleTimeString(),
        im.uploaded_by,
        <a 
          href={im.download_links.url} target="_blank" rel="noreferrer"
        >
          {im.download_links.filename}
        </a>
      ]
    }));
  }


  return (
    <div>
      <CostTemplateErrorsModal
        title='Import Errors'
        body={modalBody()}
        show={modalShow}
        onHide={() => {
          setModalShow(false);
        }}
      />

      <Row className="scrollable">
          <Col md={{ span: 6, offset: showDownloadButton ? 0 : 3 }} className="text-center">
            <Button
              isImmutableUnderReview={true}
              variant="info"
              iconPosition="left"
              size="md"
              icon="upload_file"
              loading={loading}
              className="mb-2"
              id="upload-technical-template-button"
              onClick={handleClick}
              >
                { uploadButtonText }
            </Button>
              <div>
                {renderStatus()}
              </div>
            <input
              style={{ display: 'none' }}
              type="file"
              ref={hiddenFileInput}
              data-testid='technicalTemplateUploadInput'
              onChange={(e) => submitUpload(e)}
            ></input>
          </Col>
          { showDownloadButton && 
            <Col md={6} className="text-center">   
              <Button
                variant="info"
                iconPosition="left"
                size="md"
                icon='download'
                loading={loading}
                id="download-technical-template-button"
                className="mb-4"
                onClick={generateDocument}
                >
                  { downloadButtonText }
              </Button>
            </Col>
          }
      </Row>
      <Row>
        <Col md={{offset: 3, span: 6}} className='text-center'>
          <Table headers={importTableHeaders} rows={importTableRows()} showControls={false} />
        </Col>
      </Row>
    </div>
  )
}

export default TechnicalNarrativeTemplateUploadSection